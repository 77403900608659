import { AxiosInstance } from 'axios'

export class CDDAPI {
  /**
   * Complex Drive Detector
   */

  _http: AxiosInstance

  constructor(_http: AxiosInstance) {
    this._http = _http
  }

  public getSourceList() {
    return this._http.get<CDDSourceResponse>('/event-providers').then((response) => response.data)
  }
}
