
import Vue from 'vue'
import { mapStateTyped } from '../../store'

export default Vue.extend({
  name: 'MarkerCircle',
  props: {
    id: String,
    color: String,
    tallLeg: {
      type: Boolean,
      default: false,
    },
    haveLeg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStateTyped(['isDarkTheme']),
  },
})
