import { AxiosInstance } from 'axios'
import { decodePolyline } from '@/utils'

export class OSRMAPI {
  // eslint-disable-next-line no-useless-constructor
  constructor(private _http: AxiosInstance) {}

  public getRoute(waypoints: Array<{ latitude: number; longitude: number }>): Promise<any> {
    const requests = []

    for (let index = 1; index < waypoints.length; index++) {
      const waypoint0 = waypoints[index - 1]
      const waypoint1 = waypoints[index]
      const url = `/route/v1/driving/${waypoint0.longitude},${waypoint0.latitude};${waypoint1.longitude},${waypoint1.latitude}`
      requests.push(
        this._http
          .get(url, {
            params: {
              alternatives: false,
              steps: false,
              overview: 'full',
            },
          })
          .then((response) => response.data)
          .then((response) => {
            const [route] = response.routes
            const polyline = decodePolyline(route.geometry)
            return polyline
          }),
      )
    }

    return Promise.all(requests)
  }
}
