/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { mapState, mapMutations, mapActions } from 'vuex'
import { FinderAPI } from './api'
import { groupedItems, groupedMapActionItems } from './utils'

// eslint-disable-next-line camelcase

const initialState = () => ({
  api: {} as FinderAPI,
  isDarkTheme: false as boolean,
  isSourceListLoading: false as boolean,
  isRouteAndShowLoading: false as boolean,
  sourceList: [] as CDDSource[],
  isTrafficListLoading: false as boolean,
  rawTrafficList: [] as TrafficResponseItem[],
  trafficList: [] as TrafficList,
  lastRequestResult: undefined as
    | {
        text: string
        color: 'success' | 'info' | 'error' | 'warning'
      }
    | undefined,
})

type State = ReturnType<typeof initialState>

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
}

type Mutations = typeof mutations

type ActionArguments = {
  commit: <K extends keyof Mutations>(
    method: keyof Mutations,
    payload: Parameters<Mutations[K]>[1],
  ) => void
  state: State
  dispatch: <T extends keyof Actions>(
    action: T,
    // @ts-ignore
    payload?: Parameters<Actions[T]>[1],
  ) => PromiseLike<unknown> | void
}

const actions = {
  async loadSourceList({ state, commit }: ActionArguments) {
    try {
      commit('setValue', ['isSourceListLoading', true])
      const response = await state.api?.cdd?.getSourceList?.()
      if (response && Array.isArray(response)) {
        commit('setValue', ['sourceList', response])
      } else {
        console.log('error')
      }
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      commit('setValue', ['isSourceListLoading', false])
    }
  },
  async loadTrafficList({ state, commit }: ActionArguments, params: TrafficRequest) {
    try {
      commit('setValue', ['isTrafficListLoading', true])
      let response = await state.api?.traffic?.getByNumber?.({
        ...params,
        limit: 1000,
        offset: 0,
      })
      if (!response.length) {
        commit('setValue', [
          'lastRequestResult',
          {
            text: 'Ничего не найдено',
            color: 'info',
          },
        ])
        return
      }
      if (response.length > 999) {
        const additionalResponse = await state.api?.traffic?.getByNumber?.({
          ...params,
          limit: 1000,
          offset: 1000,
        })
        additionalResponse.length > 0 && additionalResponse.length <= 1000
          ? (response = [...response, additionalResponse])
          : commit('setValue', [
              'lastRequestResult',
              {
                text: 'Показаны не все результаты, уточните критерии поиска!',
                color: 'info',
              },
            ])
      }
      const responseWithLicensePlates = response.map((item: any) => {
        return {
          ...item,
          license_plate: params.license_plate,
        }
      })
      commit('setValue', ['rawTrafficList', responseWithLicensePlates])
      const grouped = groupedItems(response)
      commit('setValue', ['trafficList', groupedMapActionItems(grouped, params.license_plate)])
    } catch (error) {
    } finally {
      commit('setValue', ['isTrafficListLoading', false])
    }
  },
}

type Actions = typeof actions

export const mapStateTyped = <T extends keyof State, G extends { [Key in T]: () => State[Key] }>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[],
): G => {
  return mapMutations(keys) as G
}

export const mapActionsTyped = <
  T extends keyof Actions,
  G extends {
    // @ts-ignore
    [Key in T]: (I?: Parameters<Actions[Key]>[1]) => ReturnType<Actions[Key]>
  },
>(
  keys: T[],
): G => {
  return mapActions(keys) as G
}

export default {
  state: initialState(),
  mutations,
  actions,
}
